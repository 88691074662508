<template>
<div>
  <br>
  <div class="row"><h2 style="margin-left: 20px">{{title}}</h2> 
    <!-- <h4 v-if="done_loading" style="margin-left: 20px; padding-top:5px" >({{total_cards(1)}})</h4> -->
    <h4 v-if="true" style="margin-left: 20px; padding-top:5px" >({{supply_current}})</h4> 
    <base-button v-if="!reload" @click="show_cards"  style="padding-bottom: 30px; margin-left: 20px; font-size: 10px;"  class="btn-link btn-neutral" > {{hide_text}} {{title}}</base-button>
    <base-button v-if="show_or_not" @click="reload_cards"  style="padding-bottom: 30px; margin-left: 20px; font-size: 10px; color: red"  class="btn-link btn-neutral" > Reload all cards</base-button>
    
    <div v-if="!done_loading" class="hollow-dots-spinner" style="">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    
  </div>
    
    <!-- <div v-if="no_cards">
    <p >No blockcards in wallet</p>
    </div> -->
    <div v-if="show_or_not" ref="box" class="row the_box"> 
                  <div v-for="nft in block_cards" :key="nft.id" class="col-lg-4">
                    <!-- <a :href="nft[1]" target="blank"> -->
                      <block-card-viewer v-if="true" :img_link="nft[0]" :img_scr="nft[1]" :nft_data="nft[2]" :name="nft[3]" :contract="nft[4]" 
                      :contract_address="nft[4]" :card_info="nft[6]" :tokenId="nft[7]" :base_num="nft[8]" :is_new="nft[9]"
                      :index_number="block_card_index_number" />
                    <!-- </a> -->
                  </div>
          <modal :show.sync="modal0" class = "nft_modal" style="">
          <template slot="header">
              <h4 class="modal-title" id="exampleModalLabel">Are you sure you want to redeem a {{title}} card?</h4>
          </template>
          
          <!-- <div class="center the_container">
              <div class="nft_image_inner"> {{modal_info.id}}
              </div>
              <div>
                <img :src="the_image" class="nft_image_inner">
                </div>
          </div> -->
          <div class="" style="height: 40%">
              
          <div class="center"> 
            <p>Keep in mind, these cards are used for bounty-rewards, trading, resale, staking and more. 
            <a href="https://discord.gg/pF8TVer5Dh" target="_blank">Learn on Discord</a> </p>
            <p>Token ID: {{get_current_card()}} </p> 
            <p>This process is not reversible </p>
              <div class="row justify-content-center" >
              <a v-if="!redeem_spin" target="blank" class=""> <base-button type="danger" class="open_button" v-on:click="redeem_card()">Redeem & Burn</base-button></a>
                <div style="" v-if="redeem_spin">
                <div class="self-building-square-spinner">
                    <div class="square"></div>
                    <div class="square"></div>
                    <div class="square"></div>
                    <div class="square clear"></div>
                    <div class="square"></div>
                    <div class="square"></div>
                    <div class="square clear"></div>
                    <div class="square"></div>
                    <div class="square"></div>
                  </div>
              </div>
              </div>
          </div>

          </div>
          <template slot="footer">
              <!-- <approve-button v-if="test_aproved" :clicked_nft="modal_info" 
                :coin_id="0" :address_to_approve="contract_data.minters[0].address" @approve="approve_coin(0)"></approve-button> -->
              <!-- <base-button type="secondary" @click="modals.modal0 = false">Close</base-button> -->
              <!-- <base-button type="primary">Save changes</base-button> -->
          </template>
        </modal>
  </div>


    </div>
</template>

<script>
import BlockCardViewer from '../components/block_card_viewer.vue';
import Web3 from 'web3';
import Modal from '@/components/Modal';
// const  { abi_nft_1 } = require("../abis.json");
const abis = require("../abis.json");
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 
let abi_nft_1 = abis.nfts[0][1];
let abi_block_pack = abis.block_packs[0][1];
let abi_block_card = abis.block_cards[0][1];
// const  { nfts } = require("../contracts_data.json");

let current_nfts;
let current_block_packs ;
let current_block_cards;
let current_read_api;


if (globals.testnet){

    current_block_cards = testnet_contracts.block_cards
    // console.log("current_block_cards", current_block_cards)
    current_read_api = globals.testnet_read_api
  }
  else{
    current_block_cards = mainnet_contracts.block_cards
    // console.log("current_block_cards", current_block_cards)
    current_read_api = globals.mainnet_read_api
  }




////////
let current_block_card_images = ["img/block_card_01_02/t/0/1/l.png"];

// nfts.map((x) => {console.log(x)})
// console.log(nfts)
// for (let key in nfts) {
//     if (nfts.hasOwnProperty(key)) {
//         console.log(key + " -> " + nfts[key]["address"]);
//     }
// }

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// not in use
const fetchJsonGetImage = async (link) =>{
  const myRequest = new Request(link);
  let data = fetch(myRequest).
  then((result) =>{
    // console.log(result)
    return result.json()
  .then((result) => {
      return result
    })
    // return result
  })
  return data
}
const compare_block_cards = (card1, card2) => {
  if (parseInt(card1[7]) > parseInt(card2[7])) {
    return -1;
  }
  if (parseInt(card1[7]) < parseInt(card2[7])) {
    return 1;
  }

  return 0;
};
const compare_string_num = (snum1, snum2) => {
  if (parseInt(snum1) > parseInt(snum2) ){
    return -1;
  }
  if (parseInt(snum1) > parseInt(snum2) ){
    return 1;
  }

  return 0;
};

const fetch_nft = async (i,j,account) => {
          //
          // let web3_1 = new Web3(window.ethereum); // todo decide what to do here
          // const url = 'https://apis-sj.ankr.com/79bea2923d214598b14290281c2955ef/10707bea3215810fb4fd4ae012380bc3/binance/full/test'  // url string
          //  const url = 'https://elemints:elemints@apis.ankr.com/binance/full/test'  // url string

          let web3_1 = new Web3(current_read_api);

          // const provider = new Web3.providers.HttpProvider("https://bsc-dataseed1.defibit.io/");
          // let web3_1 = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/");
          
          let contract_token_1 = new web3_1.eth.Contract(abi_block_card,current_block_cards[i].address)
          // console.log(account,"THE account")
          let current_nft
          let owners_balance
          let card_info 
          try{
                // current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,j).call({from: account})
                // owners_balance = await contract_token_1.methods.balanceOf(account).call({from: account})
                // card_info = await contract_token_1.methods.card_info(current_nft).call({from: account})
                current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,j).call()
                owners_balance = await contract_token_1.methods.balanceOf(account).call()
                card_info = await contract_token_1.methods.card_info(current_nft).call()
                // console.log("current token",current_nft)
                // console.log("card info",card_info)
          }
          catch(err){
              // console.log(err)
              return false
          }
          try{
            if (current_nft){
            let nft_uri = await contract_token_1.methods.tokenURI(current_nft).call()
            // console.log("token URI fetched: ", nft_uri);
            let just_hash = nft_uri.slice(7)
            // just_hash = "QmS68e8vTsy3oyXgmFzvWp2f67odtq2Bv64JUnAJwoczVA"
            let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
            // let link_result = await fetchJsonGetImage(link)
            // console.log("link_result: ", link_result)
            return (['link_result.link_image','current_block_card_images[i]',link,current_block_cards[i].name,current_block_cards[i].address,
            owners_balance,card_info,current_nft,i]);
          }

          }
          catch(err){
            console.log("uri/return failed",err)
            return false
          }
          // if (current_nft){
          //   let nft_uri = await contract_token_1.methods.tokenURI(current_nft).call()
          //   // console.log("token URI fetched: ", nft_uri);
          //   let just_hash = nft_uri.slice(7)
          //   just_hash = "QmS68e8vTsy3oyXgmFzvWp2f67odtq2Bv64JUnAJwoczVA"
          //   let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
          //   // let link_result = await fetchJsonGetImage(link)
          //   // console.log("link_result: ", link_result)
          //   return (['link_result.link_image','current_block_card_images[i]',link,current_block_cards[i].name,current_block_cards[i].address,
          //   owners_balance,card_info,current_nft,i]);
          // }
          
}

export default {
  components: { BlockCardViewer, Modal},
  name: 'BlockCardBoxMini',
   data(){ 
      let string_of_supply = "current_block_card_supply" + String(this.block_card_index_number)

      return{
        no_cards: false,
        reload: true,
        first: true,
        done_loading: false,
        componentKey: 0,
        // supply_current:  this.$store.state[string_of_supply],
        supply_current: "",
        hide_text: "Hide",
        show_or_not: this.initial_show_or_not
        }
      
  },
  computed:{
    block_cards(){
      let string_of_supply = "current_block_card_supply" + String(this.block_card_index_number)
      if (this.done_loading){
          this.supply_current = this.$store.state[string_of_supply]
      }
      return this.$store.state.block_cards_updated[this.block_card_index_number]
      
    },
    modal0: {
      get: function () {
        let block_card_r_b_modal = "block_card_r_b_modal" + String(this.block_card_index_number)
        return this.$store.state[block_card_r_b_modal]
      },
      set: function (new_value) {
        let block_card_r_b_modal = "block_card_r_b_modal" + String(this.block_card_index_number)
        //  this.$store.state.block_card_r_b_modal = new_value
        this.$store.commit('change_modal',{"modal":block_card_r_b_modal,"new_value": new_value})
        // this.$store.state[block_card_r_b_modal] = new_value
      }
    },
    redeem_spin(){
      return this.$store.state.redeem_spin
    },
    // supply_current : {
    //   get: function () {      
    //   let string_of_supply = "current_block_card_supply" + String(this.block_card_index_number)
    //   console.log("getting suppolyyyyyyy",this.$store.state[string_of_supply])
    //   let supply = this.$store.state[string_of_supply]
    //   return supply
    //   },
    //   set: function (new_value){
    //    let string_of_supply = "current_block_card_supply" + String(this.block_card_index_number)
    //    this.$store.commit('change_modal',{"modal":string_of_supply,"new_value": new_value})
    //   }

    // }
    
  },
  props: {
      block_card_index_number: { type : Number,
            default: 0 
            },
      title: {type: String, default: "BlockCards"},
      initial_show_or_not: {type: Boolean, default: true}
  },
  created: async function() {
    // console.log("NFT card box CREATED!")
    // this.supply_current = this.total_cards()
    let web3_1 = new Web3(window.ethereum);
    let accounts = await web3_1.eth.getAccounts()
    // this.$store.state.account = accounts[0]
    this.$store.commit("change_state",{name: "account", new_value: accounts[0] })
    if (!this.$store.state.block_cards_updated[this.block_card_index_number]){
      this.$store.state.block_cards_updated[this.block_card_index_number] = []
      this.$store.commit('update_block_cards',{"card_array": [],"index":this.block_card_index_number})
    }
    
  },
  mounted: async function(){
    // this.supply_current = await this.$store.state[string_of_supply]
    let string_of_show = "show_cards" + String(this.block_card_index_number)
    if (localStorage[string_of_show]){
      if(localStorage[string_of_show] == "true"){
      // cle.lonsoog(typeof Boolean(localStorage[string_of_show]), "show", localStorage[string_of_show])
        this.show_or_not = true 
        this.hide_text = "Hide"
      }
      else{
        this.show_or_not = false
        this.hide_text = "Show"
      }
    }
    this.supply_current = await this.$store.dispatch("get_total_block_card_supply",{"index": this.block_card_index_number})
    if (Number(this.supply_current) == 0){
        this.show_or_not = true 
        this.hide_text = "Hide"
      
    }
    await this.card_function()
    let string_of_supply = "current_block_card_supply" + String(this.block_card_index_number)

    // console.log("yoooooooo", this.$store.state[string_of_supply])
    
    },
    methods:{
    show_cards(){
      let string_of_show = "show_cards" + String(this.block_card_index_number)
      if(this.show_or_not == false){
        this.hide_text = "Hide"
      }
      else{
        this.hide_text = "Show"
      }
      localStorage[string_of_show] = !this.show_or_not
      this.show_or_not = !this.show_or_not
      this.card_function()
    },
    forceRerender() {
      this.componentKey += 1;
    },
      total_cards(){
        let string_of_supply = "current_block_card_supply" + String(this.block_card_index_number)
        return localStorage[string_of_supply]
      },
      async redeem_card(){
        let string_of_supply = "current_block_card_supply" + String(this.block_card_index_number)
        let string_of_cards = "current_block_cards" + String(this.block_card_index_number)
        let string_of_indexes = "card_indexes" + String(this.block_card_index_number)
        // console.log("redeeming card num: ", this.$store.state.current_block_card)
                  
                  //  this.$store.state.redeem_spin = true;
                   this.$store.commit("change_state",{name: "redeem_spin", new_value: true })
                  //  console.log("what is ist???", typeof this.$store.state.current_block_card)
                  //  return
            try{
                 let result = await this.$store.dispatch('redeem_block_card',
                 {"card_id":this.$store.state.current_block_card,
                 "base_id": this.$store.state.current_block_card_base,
                 "card_address": this.$store.state.current_block_card_address
                 })
                //  this.$store.state.redeem_spin = false;
                 this.$store.commit("change_state",{name: "redeem_spin", new_value: false })
                 let new_supply = Number(localStorage[string_of_supply])
                //  console.log('what is here???', localStorage[string_of_supply])
                 new_supply -= 1
                 localStorage[string_of_supply] = String(new_supply)
                 let current_index = this.$store.state[string_of_indexes].indexOf(this.$store.state.current_block_card)
                 this.$store.state.block_cards_updated[this.block_card_index_number].splice(current_index,1) //update to mutation
                 this.$store.state.block_cards_updated = [...this.$store.state.block_cards_updated ] //update to mutation
                 // then update indexes
                 this.$store.state[string_of_indexes].splice(current_index,1)
                 this.$store.state[string_of_indexes] = [...this.$store.state[string_of_indexes] ]
                //  this.$store.commit("change_state",{name: "string_of_indexes", new_value: [...this.$store.state[string_of_indexes] ]})
                 let stringy = JSON.stringify(this.$store.state.block_cards_updated[this.block_card_index_number])
                 localStorage[string_of_cards] = stringy
                 let block_card_r_b_modal = "block_card_r_b_modal" + String(this.block_card_index_number)
                 // update state of supply
                
                this.$store.state[string_of_supply] = new_supply
                // this.supply_current = new_supply
                
                this.$store.commit("change_state",{name: string_of_supply, new_value: new_supply })
                this.$store.state[block_card_r_b_modal] = false
                //  this.$store.state.block_card_r_b_modal = false
                 // update local storage indexes
                let stringy_indexes = JSON.stringify(this.$store.state[string_of_indexes] )
                localStorage[string_of_indexes] = stringy_indexes
                // setTimeout(() => {
                //   // window.location.reload()
                // }, 10);
                this.forceRerender() 
                 
            }
           catch(err){
               console.log(err)

           }
            // console.log("got a result")
            // this.$store.state.redeem_spin = false;
             this.$store.commit("change_state",{name: "redeem_spin", new_value: false })
           

      },
      get_current_card(){
        return this.$store.state.current_block_card
      },
      async card_function(){
      console.log("entering card function")
      this.done_loading = false
      // if (!this.show_or_not){
      //   return
      // }
    
      let web3_1 = new Web3(window.ethereum);
      let accounts = await web3_1.eth.getAccounts()
      this.$store.state.account = accounts[0]

          if (this.$store.state.block_cards_updated[this.block_card_index_number].length === 0){
          let num_block_cards = current_block_cards.length
          let keep_going = true;
          let current_cards = []
          // console.log("NUYJMMMM:",num_block_cards);
          // for(let i = 0; i < num_block_cards; i++){
              ///
              let current_block_card_indexes = []
              ///
              let string_of_supply = "current_block_card_supply" + String(this.block_card_index_number)
              let string_of_cards = "current_block_cards" + String(this.block_card_index_number)
              let string_of_indexes = "card_indexes" + String(this.block_card_index_number)

              // localStorage.string_of_supply = 0
              // console.log("current block_card supply", localStorage[string_of_supply])
              let supply_now = Number(await this.$store.dispatch("get_total_block_card_supply",{"index": this.block_card_index_number}))
          
          // experiment failed for now for resolving multiple promises
          // let promises = []
          // for (let i = 0; i < 2; i++) {
          //   promises.push(fetch_nft(this.block_card_index_number,i,this.$store.state.account));
          // }
          //  Promise.all(promises)
          // .then((results) => {
          //     console.log("promises results!", results);
          // })
          // .catch((e) => {
          //     // Handle errors here
          //     console.log(e)
          // })
              

              // console.log("current block_card supply", localStorage[string_of_supply])
              // situation when local storage has all the cards
              if (Number(localStorage[string_of_supply]) === supply_now && localStorage[string_of_cards] && (!this.reload || this.first)){
                this.first = false
                // then same supply
                // if (Number(localStorage[string_of_cards]) === supply_now && true){
                console.log("MATCHED SUPPLY")
                
                this.$store.state.block_cards_updated[this.block_card_index_number] = JSON.parse(localStorage[string_of_cards])
                // remove 'new' cards
                let cards_len = this.$store.state.block_cards_updated[this.block_card_index_number].length
                for (let card = 0 ; card < cards_len; card ++){
                  try{
                    this.$store.state.block_cards_updated[this.block_card_index_number][card][9] = false // cwcw
                  }
                  catch(err){
                    console.log(err)
                  }
                }
                this.$store.state.block_cards_updated = [...this.$store.state.block_cards_updated]
                this.$store.commit('change_state',{"name":string_of_indexes,"new_value": JSON.parse(localStorage[string_of_indexes])})
                // this.$store.state[string_of_indexes] = JSON.parse(localStorage[string_of_indexes])
                this.$store.commit('change_state',{"name":string_of_supply,"new_value": supply_now})
                // break
                this.reload = false
                this.done_loading = true
                let stringy = JSON.stringify(this.$store.state.block_cards_updated[this.block_card_index_number])
                localStorage[string_of_cards] = stringy
              
              if (this.$store.state.block_cards_updated[this.block_card_index_number].length == 0){
                  this.no_cards = true
              }
                return
              }
              else{
                console.log("NOT aMATCHED SUPPLY or no Cards found")
              }
              // break
              // if getting to here then cards are not found in local storage or the situation is reloading
              // check if not_showing_then return here to avoid overloading 
              if (!this.show_or_not){
                console.log("not showing but did not from storage")
                this.reload = false
                this.done_loading = true
                return
              }
              let j = 0
              while(keep_going){
                try{
                  let nft_call = await fetch_nft(this.block_card_index_number,j,this.$store.state.account);
                  console.log("fetching nft")
                  if (!nft_call){
                    break
                  }
              // console.log(nft_call,"NFT call!")
              // nft_call.push(current_nfts[i])
              // this.$store.state.block_cards_updated[this.block_card_index_number].push(nft_call)
              current_block_card_indexes.unshift(nft_call[7])
              // let copy_current_block_card_indexes =  JSON.parse(JSON.stringify(current_block_card_indexes))
              current_block_card_indexes.sort(function(a, b){return parseInt(b)-parseInt(a)})
              // console.log("current sort",current_block_card_indexes )
              // compare_block_cards
              this.$store.state.block_cards_updated[this.block_card_index_number].unshift(nft_call)
              this.$store.state.block_cards_updated[this.block_card_index_number].sort(function(a, b){return parseInt(b[7])-parseInt(a[7])})
              this.$store.state.block_cards_updated = [...this.$store.state.block_cards_updated]
              
                }
                catch(err){
                console.log(err)
                console.log("likely out of nfts to show")
                keep_going = false;

                }
              
              j++
              // if(j > 5){
              //   keep_going = false;
              // }
              // let stringy = JSON.stringify({"cards" : this.$store.state.block_cards})
              // let stringy = JSON.stringify(this.$store.state.block_cards)
              // localStorage[string_of_cards] = string_of_cards
              // console.log(this.$store.state.block_cards)
              // let item = this.$store.state.block_cards.pop()
              // this.$store.state.block_cards = [item]
              // console.log("wwo", stringy)
              // console.log("ww2", JSON.parse(stringy)) 

              // await timeout(100)
              }
              if (!this.$store.state.block_cards_updated[this.block_card_index_number].length == 0){
                  this.no_cards = true
              }

                    // todo fix this for all indexes
              let stringy = JSON.stringify(this.$store.state.block_cards_updated[this.block_card_index_number])
              localStorage[string_of_cards] = stringy
              // console.log(this.$store.state.block_cards_updated[this.block_card_index_number])


              localStorage[string_of_supply] = supply_now

              this.$store.commit('change_state',{"name":string_of_supply,"new_value": supply_now})

              this.$store.commit('change_state',{"name":string_of_indexes,"new_value": current_block_card_indexes})
              // this.$store.state[string_of_indexes] = current_block_card_indexes

              let stringy_indexes = JSON.stringify(this.$store.state[string_of_indexes] )
              localStorage[string_of_indexes] = stringy_indexes

              
            ///////// END FOR LOOP over 1 index of blockcards ////////// 
            // }
          
        }
        // console.log("indexesss", this.$store.state[string_of_indexes])
        this.reload = false
        console.log("getting true done loading")
        this.done_loading = true
        if (!this.$store.state.block_cards_updated[this.block_card_index_number].length == 0){
                  this.no_cards = true
              }
      },


      reload_cards(){
        if (this.reload == true){
          console.log("already reloading")
          return
        }
        this.reload = true;
        this.$store.state.block_cards_updated[this.block_card_index_number] = []
        // this.$store.state.block_cards[this.block_card_index_number] = []
        this.card_function()
      
      }
      
    }
}
</script>

<style scoped>
.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  /* border: 1px solid #000000; */
}
.image2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /* border: 1px solid #000000; */
}

.image3 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  /* border: 1px solid #000000; */
}

.hollow-dots-spinner, .hollow-dots-spinner * {
      box-sizing: border-box;
    }

    .hollow-dots-spinner {
      height: 8px;
      width: calc(30px * 3);
      margin-top: 12px;
    }

    .hollow-dots-spinner .dot {
      width: 8px;
      height: 8px;
      margin: 0 calc(8px / 2);
      border: calc(8px / 5) solid #2e9e54;
      border-radius: 50%;
      float: left;
      transform: scale(0);
      animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
    }

    .hollow-dots-spinner .dot:nth-child(1) {
      animation-delay: calc(300ms * 1);
    }

    .hollow-dots-spinner .dot:nth-child(2) {
      animation-delay: calc(300ms * 2);
    }

    .hollow-dots-spinner .dot:nth-child(3) {
      animation-delay: calc(300ms * 3);

    }

    @keyframes hollow-dots-spinner-animation {
      50% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
</style>