<template>
    <div>
        <!-- it might be better to lineup indexes of blockpacks to blockcards here -->
        <block-pack-box/>
        <!-- <block-card-box :block_card_index_number="0"/> -->
        <!-- it might be better to lineup indexes of blockpacks to blockcards here -->
        <!-- <block-card-box-mini :block_card_index_number="1" :title="'BlockCards E2'"/> -->
        <block-card-box-mini :block_card_index_number="3" :title="'Exclusive Acryptos Cards'"/>
        <block-card-box-mini :block_card_index_number="2" :title="'Fundamintals W 2022'"/>
        <block-card-box-mini :block_card_index_number="1" :title="'Holiday 2021'"/>
        <block-card-box-mini :block_card_index_number="0" :title="'BlockCards E1'"/>
        
        <!-- <nft-box/> -->
    
</div>
</template>

<script>
import BlockPackBox from '../components/block_pack_box.vue';
 import NftBox from '../components/NftBox.vue';
 import BlockCardBox from '../components/block_card_box.vue';
  import BlockCardBoxMini from '../components/block_card_box_mini.vue';
  import {globalStore} from '../main.js'

export default{

    components: {
        NftBox,
        BlockPackBox,
        BlockCardBox,
        BlockCardBoxMini
    },
    computed:{
        rerender(){
            this.forceRerender()
            console.log("getting key 1",this.$store.state.rerender)
            return this.$store.state.rerender
        }
    },
    methods: {
    forceRerender() {
      this.block_pack_key += 1;
    }
    }
    


}
</script>
