<template>
    <div style="text-align: center">
        <br>

        <img src="img/roadmap_2022.png">
        
</div>
</template>

<script>


export default{

    components: {
    },
    data(){
        return {
        }
    },
    mounted:  function() {
        // let current_packs = contract_data.block_packs
        console.log("Info and faqs")
        
    },
    computed: {
        
    }





}
</script>
<style scoped>
.responsive-youtube
{overflow:hidden; padding-bottom:56.25%; position:relative; height:0;}
.responsive-youtube iframe
{left:0; top:0; height:100%; width:100%; position:absolute;}
</style>
