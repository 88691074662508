<template>
    <div >
        <br>
        <h1 style="text-align: center">Checklists </h1>
        <h2>Acryptos Exclusive Packs 2022</h2>
        <img src="img/acs_checklist.png">
        <br><br>
        <h2>FundaMINTals 1 2022</h2>
        <img src="img/checklist_f_1_22.png">
        <br><br>
        <h2>Holiday 2021</h2>
        <img src="img/checklist_holiday_2021.png">
    </div>
</template>

<script>

import {globalStore} from '../main.js'
const abis = require("../abis.json");
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 

let abi_nft_1 = abis.nfts[0][1];
let abi_block_pack = abis.block_packs[0][1];
let abi_block_card = abis.block_cards[0][1];
// const  { nfts } = require("../contracts_data.json");


let contract_data; 

  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    contract_data = testnet_contracts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    contract_data = mainnet_contracts
  }


export default{

    components: {
    },
    data(){
        return {
        }
    },
    mounted:  function() {
        // let current_packs = contract_data.block_packs
        console.log("Info and faqs")
        
    },
    computed: {
        
    }


}
</script>
<style scoped>
.responsive-youtube
{overflow:hidden; padding-bottom:56.25%; position:relative; height:0;}
.responsive-youtube iframe
{left:0; top:0; height:100%; width:100%; position:absolute;}
</style>
