<template>
<div>
  <br>
    <div class="row"><h2 style="margin-left: 20px">BlockCards</h2> 
    <h4 v-if="done_loading" style="margin-left: 20px; padding-top:5px" >({{total_cards(1)}})</h4>
    <base-button @click="reload_cards"  style="padding-bottom: 30px; margin-left: 20px; font-size: 10px"  class="btn-link btn-neutral" > Reload all cards</base-button></div>
    <div v-if="no_cards">
    <p >No blockcards in wallet</p>
    </div>
        <div v-if="true" ref="box" class="row the_box">
            <div v-for="nft in block_cards" :key="nft.id" class="col-lg-4">
              <!-- <a :href="nft[1]" target="blank"> -->
                <block-card-viewer v-if="true" :img_link="nft[0]" :img_scr="nft[1]" :nft_data="nft[2]" :name="nft[3]" :contract="nft[4]" 
                :contract_address="nft[4]" :card_info="nft[6]" :tokenId="nft[7]" :base_num="nft[8]"/>
              <!-- </a> -->
            </div>
    <modal :show.sync="modal0" class = "nft_modal" style="">
     <template slot="header">
        <h4 class="modal-title" id="exampleModalLabel">Are you sure you want to redeem?</h4>
       
     </template>
    
     <!-- <div class="center the_container">
        <div class="nft_image_inner"> {{modal_info.id}}
        </div>
        <div>
          <img :src="the_image" class="nft_image_inner">
          </div>
     </div> -->
    <div class="" style="height: 40%">
        
    <div class="center"> 
      <p>Token ID: {{get_current_card()}} </p> 
      <p>This process is not reversible </p>
        <div class="row justify-content-center" >
        <a v-if="!redeem_spin" target="blank" class=""> <base-button type="danger" class="open_button" v-on:click="redeem_card()">Redeem & Burn</base-button></a>
           <div style="" v-if="redeem_spin">
           <div class="self-building-square-spinner">
              <div class="square"></div>
              <div class="square"></div>
              <div class="square"></div>
              <div class="square clear"></div>
              <div class="square"></div>
              <div class="square"></div>
              <div class="square clear"></div>
              <div class="square"></div>
              <div class="square"></div>
            </div>
         </div>
        </div>
    </div>

</div>
     <template slot="footer">
      
         <!-- <approve-button v-if="test_aproved" :clicked_nft="modal_info" 
          :coin_id="0" :address_to_approve="contract_data.minters[0].address" @approve="approve_coin(0)"></approve-button> -->
         <!-- <base-button type="secondary" @click="modals.modal0 = false">Close</base-button> -->
         <!-- <base-button type="primary">Save changes</base-button> -->
     </template>
   </modal>
        </div>


    </div>
</template>

<script>
import BlockCardViewer from '../components/block_card_viewer.vue';
import Web3 from 'web3';
import Modal from '@/components/Modal';
// const  { abi_nft_1 } = require("../abis.json");
const abis = require("../abis.json");
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 
let abi_nft_1 = abis.nfts[0][1];
let abi_block_pack = abis.block_packs[0][1];
let abi_block_card = abis.block_cards[0][1];
// const  { nfts } = require("../contracts_data.json");

let current_nfts;
let current_block_packs ;
let current_block_cards;


if (globals.testnet){

    current_block_cards = testnet_contracts.block_cards
    // console.log("current_block_cards", current_block_cards)
  }
  else{
    current_block_cards = mainnet_contracts.block_cards
    // console.log("current_block_cards", current_block_cards)
  }




////////
let current_block_card_images = ["img/block_card_01_02/t/0/1/l.png"];

// nfts.map((x) => {console.log(x)})
// console.log(nfts)
// for (let key in nfts) {
//     if (nfts.hasOwnProperty(key)) {
//         console.log(key + " -> " + nfts[key]["address"]);
//     }
// }

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


const fetchJsonGetImage = async (link) =>{
  const myRequest = new Request(link);
  let data = fetch(myRequest).
  then((result) =>{
    console.log(result)
    return result.json()
  .then((result) => {
      return result
    })
    // return result
  })
  return data
}
const fetch_nft = async (i,j,account) => {
          //
          let web3_1 = new Web3(window.ethereum);
          let contract_token_1 = new web3_1.eth.Contract(abi_block_card,current_block_cards[i].address)
          console.log(account,"THE account")
          let current_nft
          let owners_balance
          let card_info 
          try{
                current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,j).call({from: account})
                owners_balance = await contract_token_1.methods.balanceOf(account).call({from: account})
                card_info = await contract_token_1.methods.card_info(current_nft).call({from: account})
                console.log("current token",current_nft)
                console.log("card info",card_info)
          }
          catch(err){
              // console.log(err)
              return false
          }
          if (current_nft){
            let nft_uri = await contract_token_1.methods.tokenURI(current_nft).call()
            console.log("token URI fetched: ", nft_uri);
            let just_hash = nft_uri.slice(7)
            just_hash = "QmS68e8vTsy3oyXgmFzvWp2f67odtq2Bv64JUnAJwoczVA"
            let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
            // let link_result = await fetchJsonGetImage(link)
            // console.log("link_result: ", link_result)
            return (['link_result.link_image',current_block_card_images[i],link,current_block_cards[i].name,current_block_cards[i].address,
            owners_balance,card_info,current_nft,i]);
          }
          
}

export default {
  components: { BlockCardViewer, Modal},
  name: 'BlockPackBox',
   data(){ 
      return{
        no_cards: false,
        reload: false,
        done_loading: false
               
        }
      
  },
  computed:{

    block_cards(){
      return this.$store.state.block_cards
      // return this.$store.state.block_cards[this.block_card_index_number]
    },
    modal0: {
      get: function () {
        return this.$store.state.block_card_r_b_modal
      },
      set: function (new_value) {
         this.$store.state.block_card_r_b_modal = new_value
      }
    },
    redeem_spin(){
      return this.$store.state.redeem_spin
    }
  },
  props: {
      block_card_index_number: { type : Number,
            default: 0 
            }
  },
  created: async function() {
    console.log("NFT BOX CREATED!")
    let web3_1 = new Web3(window.ethereum);
    let accounts = await web3_1.eth.getAccounts()
    this.$store.state.account = accounts[0]
    // for (let i =0; i<3; i++){
    //     let instance = Vue.extend(NftViewer)
    //     instance.$mount()
    //     this.$refs.box.appendChild(instance.$el)
    // }
  },
  mounted: async function(){
    this.card_function()
    // this.card_function2()
    },
    methods:{
      total_cards(n){
        return localStorage.string_of_supply
      },
      async redeem_card(){

        console.log("redeeming card num: ", this.$store.state.current_block_card)
                   this.$store.state.redeem_spin = true;
                  //  console.log("what is ist???", typeof this.$store.state.current_block_card)
                  //  return
            try{
                 let result = await this.$store.dispatch('redeem_block_card',
                 {"card_id":this.$store.state.current_block_card,
                 "base_id": this.$store.state.current_block_card_base,
                 "card_address": this.$store.state.current_block_card_address
                 })
                 this.$store.state.redeem_spin = false;
                 let new_supply = Number(localStorage.string_of_supply)
                 console.log('what is here???', localStorage.string_of_supply)
                 new_supply -= 1
                 localStorage.string_of_supply = String(new_supply)
                 let current_index = this.$store.state.string_of_indexes.indexOf(this.$store.state.current_block_card)
                 this.$store.state.block_cards.splice(current_index,1)
                 let stringy = JSON.stringify(this.$store.state.block_cards)
                 localStorage.string_of_cards = stringy
                setTimeout(() => {
                  window.location.reload()
                }, 10);
                 
            }
           catch(err){
               console.log(err)

           }
            console.log("got a result")
            this.$store.state.redeem_spin = false;
           

      },
      get_current_card(){
        return this.$store.state.current_block_card
      },
      async card_function(){
      console.log("entering card function")
      let web3_1 = new Web3(window.ethereum);
      let accounts = await web3_1.eth.getAccounts()
      this.$store.state.account = accounts[0]

      let check_supply = Number(await this.$store.dispatch("get_total_block_card_suppy",{"index": i}))
      
          if (this.$store.state.block_cards.length === 0 || Number(localStorage.string_of_supply) !== check_supply){
          let num_block_cards = current_block_cards.length
          let keep_going = true;
          let current_cards = []
          console.log("NUYJMMMM:",num_block_cards);
          for(let i = 0; i < num_block_cards; i++){
              ///
              let current_block_card_indexes = []
              ///
              let string_of_supply = "current_block_card_supply" + String(i)
              let string_of_cards = "current_block_cards" + String(i)
              let string_of_indexes = "card_indexes" + String(i)

              // localStorage.string_of_supply = 0
              console.log("current block_card supply", localStorage.string_of_supply)
              let supply_now = Number(await this.$store.dispatch("get_total_block_card_suppy",{"index": i}))
              console.log("current block_card supply", localStorage.string_of_supply)
              if (Number(localStorage.string_of_supply) === supply_now && localStorage.string_of_cards && !this.reload){
                // if (Number(localStorage.string_of_cards) === supply_now && true){
                console.log("MATCHED SUPPLY",localStorage.string_of_supply)
                
                this.$store.state.block_cards = JSON.parse(localStorage.string_of_cards)
                this.$store.state.string_of_indexes = JSON.parse(localStorage.string_of_indexes)
                break
              }
              else{
                console.log("NOT aMATCHED SUPPLY or no Cards found")
              }
              // break
              let j = 0
              while(keep_going){
                try{
                  let nft_call = await fetch_nft(i,j,this.$store.state.account);
                  if (!nft_call){
                    break
                  }
              console.log(nft_call,"NFT call!")
              // nft_call.push(current_nfts[i])
              // this.$store.state.block_cards.push(nft_call)
              current_block_card_indexes.unshift(nft_call[7])
              this.$store.state.block_cards.unshift(nft_call)
              
                }
                catch(err){
                // console.log(err)
                console.log("likely out of nfts to show")
                keep_going = false;

                }
              
              j++
              // if(j > 5){
              //   keep_going = false;
              // }
              // let stringy = JSON.stringify({"cards" : this.$store.state.block_cards})
              // let stringy = JSON.stringify(this.$store.state.block_cards)
              // localStorage.string_of_cards = string_of_cards
              // console.log(this.$store.state.block_cards)
              // let item = this.$store.state.block_cards.pop()
              // this.$store.state.block_cards = [item]
              // console.log("wwo", stringy)
              // console.log("ww2", JSON.parse(stringy)) 

              // await timeout(0.01)
              }
              if (this.$store.state.block_cards.length == 0){
                  this.no_cards = true
              }

                    // todo fix this for all indexes
              let stringy = JSON.stringify(this.$store.state.block_cards)
              localStorage.string_of_cards = stringy
              console.log(this.$store.state.block_cards)


              localStorage.string_of_supply = supply_now

              this.$store.state.string_of_indexes = current_block_card_indexes

              let stringy_indexes = JSON.stringify(this.$store.state.string_of_indexes )
              localStorage.string_of_indexes = stringy_indexes

              
            ///////// END FOR LOOP over 1 index of blockcards ////////// 
            }
          
        }
        console.log("indexesss", this.$store.state.string_of_indexes)
        this.reload = false
        this.done_loading = true
      },

      async card_function2(){
      console.log("entering card function")
      let web3_1 = new Web3(window.ethereum);
      let accounts = await web3_1.eth.getAccounts()
      this.$store.state.account = accounts[0]
      // block_card_index_number
          // if (this.$store.state.block_cards[this.block_card_index_number].length === 0){
          if (!this.$store.state.block_cards[this.block_card_index_number]){
          let num_block_cards = current_block_cards.length
          let keep_going = true;
          let current_cards = []
          console.log("NUYJMMMM:",num_block_cards);
          // for(let i = 0; i < num_block_cards; i++){
              ///
              let current_block_card_indexes = []
              ///
              let string_of_supply = "current_block_card_supply" + String(this.block_card_index_number)
              let string_of_cards = "current_block_cards" + String(this.block_card_index_number)
              let string_of_indexes = "card_indexes" + String(this.block_card_index_number)

              // localStorage.string_of_supply = 0
              console.log("current block_card supply", localStorage.string_of_supply)
              let supply_now = Number(await this.$store.dispatch("get_total_block_card_suppy",{"index": this.block_card_index_number}))
              console.log("current block_card supply", localStorage.string_of_supply)
              if (Number(localStorage.string_of_supply) === supply_now && localStorage.string_of_cards && !this.reload && false){
                // if (Number(localStorage.string_of_cards) === supply_now && true){
                console.log("MATCHED SUPPLY")
                
                this.$store.state.block_cards[this.block_card_index_number]= JSON.parse(localStorage.string_of_cards)
                this.$store.state.string_of_indexes = JSON.parse(localStorage.string_of_indexes)
                // return
              }
              else{
                console.log("NOT aMATCHED SUPPLY or no Cards found")
              }
              // break
              let j = 0
              while(keep_going){
                try{
                  console.log(this.block_card_index_number, "the number!!!")
                  let nft_call = await fetch_nft(this.block_card_index_number,j,this.$store.state.account);
                  if (!nft_call){
                    break
                  }
              console.log(nft_call,"NFT call!")
              // nft_call.push(current_nfts[i])
              // this.$store.state.block_cards[this.block_card_index_number].push(nft_call)
              current_block_card_indexes.unshift(nft_call[7])
              this.$store.state.block_cards[this.block_card_index_number].unshift(nft_call)
              
                }
                catch(err){
                // console.log(err)
                console.log("likely out of nfts to show",err)
                keep_going = false;

                }
              
              j++
              // if(j > 5){
              //   keep_going = false;
              // }
             

              await timeout(0.01)
              }
              if (!this.$store.state.block_cards[this.block_card_index_number]){
                  this.no_cards = true
              }

                    // todo fix this for all indexes
              let stringy = JSON.stringify(this.$store.state.block_cards[this.block_card_index_number])
              localStorage.string_of_cards = stringy
              console.log(this.$store.state.block_cards[this.block_card_index_number])


              localStorage.string_of_supply = supply_now

              this.$store.state.string_of_indexes = current_block_card_indexes

              let stringy_indexes = JSON.stringify(this.$store.state.string_of_indexes )
              localStorage.string_of_indexes = stringy_indexes

              
            ///////// END FOR LOOP over 1 index of blockcards ////////// 
            // }
          
        }
        console.log("indexesss", this.$store.state.string_of_indexes)
        this.reload = false
        this.done_loading = true
      },


      reload_cards(){
        this.reload = true;
        this.$store.state.block_cards = []
        // this.$store.state.block_cards[this.block_card_index_number] = []
        this.card_function()
        // this.card_function2()
      }
      
    }
}
</script>

<style scoped>
.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  /* border: 1px solid #000000; */
}
.image2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /* border: 1px solid #000000; */
}

.image3 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  /* border: 1px solid #000000; */
}
</style>