<template>
    <div style="text-align: center">
        <br>
        <h1>Token ($ELMNT) and Staking Coming Soon!</h1>
       
        <h2> <a target="_blank" href="https://medium.com/@elemintcollectable/elemints-not-just-another-nft-19a50152f6db">Learn More about our Token</a></h2>
        <!-- <img src="img/tokenomics.png"> -->
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQdf9_VZKnTXKxK5xo7d1zB-Q7GBXQNj20d89-IB23ec7VByFyWRfsxJZcvg8xP6onIp4YF80RUlhoP/embed?start=false&loop=false&delayms=3000" frameborder="0" width="90%" height="749" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
        
</div>
</template>

<script>


export default{

    components: {
    },
    data(){
        return {
        }
    },
    mounted:  function() {
        // let current_packs = contract_data.block_packs
        console.log("Info and faqs")
        
    },
    computed: {
        
    }





}
</script>
<style scoped>
.responsive-youtube
{overflow:hidden; padding-bottom:56.25%; position:relative; height:0;}
.responsive-youtube iframe
{left:0; top:0; height:100%; width:100%; position:absolute;}
</style>
